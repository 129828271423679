// extracted by mini-css-extract-plugin
export default {"home":"home_home__Jsjxk","chartCard":"home_chartCard__t8HJf","title":"home_title__L-qc9","tag":"home_tag__1Ru1x","searchBox":"home_searchBox__LriH5","searchButton":"home_searchButton__a3blh","innerTitle":"home_innerTitle__dXzKq","chart":"home_chart__Nt1YI"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"home\":\"home_home__Jsjxk\",\"chartCard\":\"home_chartCard__t8HJf\",\"title\":\"home_title__L-qc9\",\"tag\":\"home_tag__1Ru1x\",\"searchBox\":\"home_searchBox__LriH5\",\"searchButton\":\"home_searchButton__a3blh\",\"innerTitle\":\"home_innerTitle__dXzKq\",\"chart\":\"home_chart__Nt1YI\"}";
        // 1739351922834
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  