// extracted by mini-css-extract-plugin
export default {"primaryColor":"#1890ff","backColor":"#f0f2f5","buttonSpacing":"8px","textButtonSpacing":"4px","formTableBox":"formTable_formTableBox__IzkcC","titleBox":"formTable_titleBox__x0hO9","itemTitleBox":"formTable_itemTitleBox__23PPT","itemContentBox":"formTable_itemContentBox__D6rAR"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"primaryColor\":\"#1890ff\",\"backColor\":\"#f0f2f5\",\"buttonSpacing\":\"8px\",\"textButtonSpacing\":\"4px\",\"formTableBox\":\"formTable_formTableBox__IzkcC\",\"titleBox\":\"formTable_titleBox__x0hO9\",\"itemTitleBox\":\"formTable_itemTitleBox__23PPT\",\"itemContentBox\":\"formTable_itemContentBox__D6rAR\"}";
        // 1739351930931
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  