// extracted by mini-css-extract-plugin
export default {"primaryColor":"#1890ff","backColor":"#f0f2f5","buttonSpacing":"8px","textButtonSpacing":"4px","atShowBoxTitle":"atCommand_atShowBoxTitle__KS0fp","atShowBox":"atCommand_atShowBox__k-iNc","chatBox":"atCommand_chatBox__KVsom","chatItem":"atCommand_chatItem__tGtvP","chat":"atCommand_chat__ETkaP","commandBox":"atCommand_commandBox__LMqjt","tableAtName":"atCommand_tableAtName__f-2lr","atSendForm":"atCommand_atSendForm__Mz28Z","buttomFormItem":"atCommand_buttomFormItem__Jcaqk","formContent":"atCommand_formContent__fenmW","tip":"atCommand_tip__778+X"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"primaryColor\":\"#1890ff\",\"backColor\":\"#f0f2f5\",\"buttonSpacing\":\"8px\",\"textButtonSpacing\":\"4px\",\"atShowBoxTitle\":\"atCommand_atShowBoxTitle__KS0fp\",\"atShowBox\":\"atCommand_atShowBox__k-iNc\",\"chatBox\":\"atCommand_chatBox__KVsom\",\"chatItem\":\"atCommand_chatItem__tGtvP\",\"chat\":\"atCommand_chat__ETkaP\",\"commandBox\":\"atCommand_commandBox__LMqjt\",\"tableAtName\":\"atCommand_tableAtName__f-2lr\",\"atSendForm\":\"atCommand_atSendForm__Mz28Z\",\"buttomFormItem\":\"atCommand_buttomFormItem__Jcaqk\",\"formContent\":\"atCommand_formContent__fenmW\",\"tip\":\"atCommand_tip__778+X\"}";
        // 1739351924167
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  