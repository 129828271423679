// extracted by mini-css-extract-plugin
export default {"dataBox":"dataSizeDetails_dataBox__iZHp2","dataBoxLittleTitle":"dataSizeDetails_dataBoxLittleTitle__R2R8S","dataBoxTitle":"dataSizeDetails_dataBoxTitle__K2RnA","dataBoxContent":"dataSizeDetails_dataBoxContent__6pkPN","dataBoxContentLeft":"dataSizeDetails_dataBoxContentLeft__z1Iho","leftChartOne":"dataSizeDetails_leftChartOne__0LrjX","dataBoxContentLeftItemTitle":"dataSizeDetails_dataBoxContentLeftItemTitle__oVzSU","dataBoxContentLeftList":"dataSizeDetails_dataBoxContentLeftList__yEADp","dataBoxContentLeftNone":"dataSizeDetails_dataBoxContentLeftNone__4DpvF","dataBoxContentLeftItem":"dataSizeDetails_dataBoxContentLeftItem__HwVBs","dataBoxContentLeftItemLeft":"dataSizeDetails_dataBoxContentLeftItemLeft__hDPQu","dataBoxContentLeftItemMiddle":"dataSizeDetails_dataBoxContentLeftItemMiddle__OiSS1","dataBoxContentLeftItemRight":"dataSizeDetails_dataBoxContentLeftItemRight__iSkzy","dataBoxContentMiddle":"dataSizeDetails_dataBoxContentMiddle__Ohg7T","dataBoxContentMiddleTitle":"dataSizeDetails_dataBoxContentMiddleTitle__qkZbp","mapBox":"dataSizeDetails_mapBox__hVlIk","dataBoxContentRight":"dataSizeDetails_dataBoxContentRight__kDztI","rightChartOne":"dataSizeDetails_rightChartOne__ZOIZ2","rightChartTwo":"dataSizeDetails_rightChartTwo__TlRdk","rightChartThree":"dataSizeDetails_rightChartThree__aG89V"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"dataBox\":\"dataSizeDetails_dataBox__iZHp2\",\"dataBoxLittleTitle\":\"dataSizeDetails_dataBoxLittleTitle__R2R8S\",\"dataBoxTitle\":\"dataSizeDetails_dataBoxTitle__K2RnA\",\"dataBoxContent\":\"dataSizeDetails_dataBoxContent__6pkPN\",\"dataBoxContentLeft\":\"dataSizeDetails_dataBoxContentLeft__z1Iho\",\"leftChartOne\":\"dataSizeDetails_leftChartOne__0LrjX\",\"dataBoxContentLeftItemTitle\":\"dataSizeDetails_dataBoxContentLeftItemTitle__oVzSU\",\"dataBoxContentLeftList\":\"dataSizeDetails_dataBoxContentLeftList__yEADp\",\"dataBoxContentLeftNone\":\"dataSizeDetails_dataBoxContentLeftNone__4DpvF\",\"dataBoxContentLeftItem\":\"dataSizeDetails_dataBoxContentLeftItem__HwVBs\",\"dataBoxContentLeftItemLeft\":\"dataSizeDetails_dataBoxContentLeftItemLeft__hDPQu\",\"dataBoxContentLeftItemMiddle\":\"dataSizeDetails_dataBoxContentLeftItemMiddle__OiSS1\",\"dataBoxContentLeftItemRight\":\"dataSizeDetails_dataBoxContentLeftItemRight__iSkzy\",\"dataBoxContentMiddle\":\"dataSizeDetails_dataBoxContentMiddle__Ohg7T\",\"dataBoxContentMiddleTitle\":\"dataSizeDetails_dataBoxContentMiddleTitle__qkZbp\",\"mapBox\":\"dataSizeDetails_mapBox__hVlIk\",\"dataBoxContentRight\":\"dataSizeDetails_dataBoxContentRight__kDztI\",\"rightChartOne\":\"dataSizeDetails_rightChartOne__ZOIZ2\",\"rightChartTwo\":\"dataSizeDetails_rightChartTwo__TlRdk\",\"rightChartThree\":\"dataSizeDetails_rightChartThree__aG89V\"}";
        // 1739351928139
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  