// extracted by mini-css-extract-plugin
export default {"rechargeBox":"rechargeModal_rechargeBox__OQqTi","rechargeBoxRow":"rechargeModal_rechargeBoxRow__kUJQS","rechargeBoxCol":"rechargeModal_rechargeBoxCol__BJfdp","rechargeAmount":"rechargeModal_rechargeAmount__AF+ZI","rechargeAmountButton":"rechargeModal_rechargeAmountButton__-5DX8","rechargeAmountSpan":"rechargeModal_rechargeAmountSpan__8UO55"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"rechargeBox\":\"rechargeModal_rechargeBox__OQqTi\",\"rechargeBoxRow\":\"rechargeModal_rechargeBoxRow__kUJQS\",\"rechargeBoxCol\":\"rechargeModal_rechargeBoxCol__BJfdp\",\"rechargeAmount\":\"rechargeModal_rechargeAmount__AF+ZI\",\"rechargeAmountButton\":\"rechargeModal_rechargeAmountButton__-5DX8\",\"rechargeAmountSpan\":\"rechargeModal_rechargeAmountSpan__8UO55\"}";
        // 1739351928233
        var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  