// extracted by mini-css-extract-plugin
export default {"carGroupMoveBox":"carGroupMove_carGroupMoveBox__JKG4T","carTree":"carGroupMove_carTree__TWx3l","moveButtonBox":"carGroupMove_moveButtonBox__qyLUh","rightPart":"carGroupMove_rightPart__5Ff0z","carBoxTitle":"carGroupMove_carBoxTitle__kt7zZ","carBox":"carGroupMove_carBox__GOFqM","carItem":"carGroupMove_carItem__v6jJ0"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"carGroupMoveBox\":\"carGroupMove_carGroupMoveBox__JKG4T\",\"carTree\":\"carGroupMove_carTree__TWx3l\",\"moveButtonBox\":\"carGroupMove_moveButtonBox__qyLUh\",\"rightPart\":\"carGroupMove_rightPart__5Ff0z\",\"carBoxTitle\":\"carGroupMove_carBoxTitle__kt7zZ\",\"carBox\":\"carGroupMove_carBox__GOFqM\",\"carItem\":\"carGroupMove_carItem__v6jJ0\"}";
        // 1739351923232
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  