// extracted by mini-css-extract-plugin
export default {"ultrasonicLocationBox":"sensorUltrasonicLocation_ultrasonicLocationBox__rDAvO","leftBox":"sensorUltrasonicLocation_leftBox__hoyGw","rightBox":"sensorUltrasonicLocation_rightBox__ktzfP","selectItemOne":"sensorUltrasonicLocation_selectItemOne__Z6t66","selectItemTwo":"sensorUltrasonicLocation_selectItemTwo__1agfU","selectItemThree":"sensorUltrasonicLocation_selectItemThree__LxRR9","selectItemFour":"sensorUltrasonicLocation_selectItemFour__7Jli9","selectItemFive":"sensorUltrasonicLocation_selectItemFive__c-8ye","selectItemSix":"sensorUltrasonicLocation_selectItemSix__G3EJP","middleBox":"sensorUltrasonicLocation_middleBox__VlYOh","middleImage":"sensorUltrasonicLocation_middleImage__wHr2Q","middleTop":"sensorUltrasonicLocation_middleTop__5UInl","line":"sensorUltrasonicLocation_line__d5Al0","middleBottom":"sensorUltrasonicLocation_middleBottom__d3Dka","middleText":"sensorUltrasonicLocation_middleText__V0ojh","selectItem":"sensorUltrasonicLocation_selectItem__IftDg","selectItemColumn":"sensorUltrasonicLocation_selectItemColumn__OEuB9","displaySelect":"sensorUltrasonicLocation_displaySelect__Dzpfy","point":"sensorUltrasonicLocation_point__L7J5b"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"ultrasonicLocationBox\":\"sensorUltrasonicLocation_ultrasonicLocationBox__rDAvO\",\"leftBox\":\"sensorUltrasonicLocation_leftBox__hoyGw\",\"rightBox\":\"sensorUltrasonicLocation_rightBox__ktzfP\",\"selectItemOne\":\"sensorUltrasonicLocation_selectItemOne__Z6t66\",\"selectItemTwo\":\"sensorUltrasonicLocation_selectItemTwo__1agfU\",\"selectItemThree\":\"sensorUltrasonicLocation_selectItemThree__LxRR9\",\"selectItemFour\":\"sensorUltrasonicLocation_selectItemFour__7Jli9\",\"selectItemFive\":\"sensorUltrasonicLocation_selectItemFive__c-8ye\",\"selectItemSix\":\"sensorUltrasonicLocation_selectItemSix__G3EJP\",\"middleBox\":\"sensorUltrasonicLocation_middleBox__VlYOh\",\"middleImage\":\"sensorUltrasonicLocation_middleImage__wHr2Q\",\"middleTop\":\"sensorUltrasonicLocation_middleTop__5UInl\",\"line\":\"sensorUltrasonicLocation_line__d5Al0\",\"middleBottom\":\"sensorUltrasonicLocation_middleBottom__d3Dka\",\"middleText\":\"sensorUltrasonicLocation_middleText__V0ojh\",\"selectItem\":\"sensorUltrasonicLocation_selectItem__IftDg\",\"selectItemColumn\":\"sensorUltrasonicLocation_selectItemColumn__OEuB9\",\"displaySelect\":\"sensorUltrasonicLocation_displaySelect__Dzpfy\",\"point\":\"sensorUltrasonicLocation_point__L7J5b\"}";
        // 1739351930933
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  