/**
 * 驾驶行为分析报警详情弹窗
 */
import React, {useEffect, useRef, useState} from "react";
import {Modal, Row, Col, message, Button} from "antd";
import gcoord from "gcoord";
import flvjs from "flv.js";
import mpegts from 'mpegts.js';
import dayjs from "dayjs";
import {importAliMap, destroyAliMap} from "@/utils/map";
import {downloadFile, downloadUrl} from "@/utils/download";
import {showInfoWindow, renderEventType, showInfoWindowV2} from "@/utils/driveAnalysis";
import {formatTime} from "@/utils/formatTime";
import {changeUrlProtocol} from "@/utils/common";
import {operateFtpResource, deviceOperateFtpResource} from "@/utils/jtDevice";
import {getInnerPermissionEnable} from "@/utils/permission";
import {operateJt808VersionPath} from "@/utils/jtDeviceRequest";
import {formSearchGutter} from "@/constants/common";
import {driveVideoErrorMessage} from "@/constants/driveVideo";
import {ftpResourceRequest} from "@/service/jtDevicePlayback";
import {
  jt808Command9205Request,
  jt808Command9201Request,
  jt808Command9202Request,
} from "@/service/jtDevicePlayback";
import AlarmInfoModal from "@/components/alarmInfoModal";
import CommonTable from "@/components/commonTable";
import RecorderVideoPreviewModal from "@/components/recorderVideoPreviewModal";
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import pageStyles from "@/styles/pageContent.module.scss";
import styles from "./index.module.scss";
import {jtDeviceDetailRequest} from "@/service/jtDevice";
import {streamTransfer} from "@/pages/jtDevicePlayback/components/ftpResourceTable";

interface AlarmDetailModalProps {
  data: any; // 报警数据
  closeModal: () => void; // 关闭弹窗的方法
  carDetail: any; // 车辆以及车辆绑定的部标机相关信息
  transferCallback: (newUrl: string) => void; // 添加字幕成功后的方法
  alarmResourceInfo: any; // 报警信息的记录仪视频信息
}

const AlarmDetailModal = (props: AlarmDetailModalProps) => {
  const mapDomRef = useRef(null); // 渲染地图的DOM
  const mapRef: any = useRef(null); // 存储地图实例
  const jtDevicePlaybackParamsRef: any = useRef(null); // 存储部标机设备回放参数(用于终止视频回放)
  const flvPlayerRef: any = useRef(null); // 播放器实例
  const {data, closeModal, carDetail, transferCallback, alarmResourceInfo} =
    props;
  const [videoData, setVideoData] = useState<any>([]); // 视频数据
  const [loading, setLoading] = useState<boolean>(false); // 视频数据获取的loading状态
  const [ftpVideoUrl, setFtpVideoUrl] = useState<string>(""); // 播放ftp服务器视频的url
  const [dvrVideoVisible, setDvrVideoVisible] = useState<boolean>(false); // 显示记录仪视频播放组件
  const [playLoading, setPlayLoading] = useState<any>([]); // 播放loading控制
  const [downloadLoading, setDownloadLoading] = useState<any>([]); // 下载loading控制

  useEffect(() => {
    importAliMap(initMap);
    getFtpResource();
    return () => {
      closeJtDevicePlayback();
    };
  }, []);

  // 地图初始化
  const initMap = async () => {
    try {
      mapRef.current = new window.AMap.Map(mapDomRef.current, {
        center: [116.368904, 39.913423],
        zoom: 16,
        animateEnable: false,
        WebGLParams: {
          preserveDrawingBuffer: true,
        },
      });
      if (data.lon && data.lat) {
        const transformGcoord = gcoord.transform(
          [data.lat, data.lon],
          gcoord.WGS84,
          gcoord.AMap
        );
        const lnglat = new window.AMap.LngLat(
          transformGcoord[0],
          transformGcoord[1]
        );
        const marker = new window.AMap.Marker({
          position: lnglat,
          offset: new window.AMap.Pixel(0, 20),
        });
        marker.originData = data;
        mapRef.current.setCenter(lnglat);
        mapRef.current.add(marker);
        marker.on("click", (point: any) => showInfoWindowV2(point, mapRef));
        showInfoWindowV2({lnglat, target: {originData: data}}, mapRef);
      }
    } catch (error) {
      message.error("地图初始化失败");
    }
  };

  // 获取ftp服务器资源列表
  const getFtpResource = async () => {
    setLoading(true);
    // const newVideoData = [...videoData];
    const newVideoData = []
    // 有记录仪视频数据时存储
    if (data.attachUrl || alarmResourceInfo[data.alarmResourceKey]) {
      newVideoData.push({videoSource: "dvr"});
    }
    try {
      const {jt808DeviceId, plateNumber} = carDetail;
      if (jt808DeviceId) {
        // 车辆绑定部标机设备时获取ftp服务器资源列表
        const params: any = {
          plateNumber,
          reportedTime: data.reportedTime,
        };
        const res = await ftpResourceRequest(params);
        const resData = res.data?.data || [];
        if (resData.length) {
          resData.forEach((item: any) => {
            newVideoData.push({
              ...item,
              videoSource: "ftp",
            });
          });
          setVideoData(newVideoData);
        } else {
          // ftp服务器没有数据时获取部标机设备的资源列表
          await getJtDeviceResource(newVideoData);
        }
      } else {
        setVideoData(newVideoData);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setVideoData(newVideoData);
    }
  };

  // 获取部标机设备的资源列表
  const getJtDeviceResource = async (newVideoData: any) => {
    const {channelNoList, terminalPhone, jt808DeviceId} = carDetail;
    // 获取资源列表的时间范围
    const start = formatTime(dayjs(data.reportedTime).subtract(30, "second"));
    const end = formatTime(dayjs(data.reportedTime).add(30, "second"));
    const all9205Request = channelNoList.map((item: number) => {
      const params = {
        phone: terminalPhone,
        jt808DeviceId,
        channelNo: item,
        codeStream: 1,
        start,
        end,
        type: 0, // 音视频类型
        storageType: 0, // 所有存储器
        alarmFlag: 0,
        alarmFlag1: 0,
      };
      return jt808Command9205Request(params);
    });
    const all9205Res = await Promise.all(all9205Request);
    all9205Res.forEach((itemRes: any) => {
      (itemRes.data?.data?.resourceList || []).forEach((item: any) => {
        newVideoData.push({
          ...item,
          videoSource: "adas",
        });
      });
    });
    setVideoData(newVideoData);
  };

  // 关闭视频回放
  const closeJtDevicePlayback = async () => {
    if (jtDevicePlaybackParamsRef.current) {
      try {
        const {channelNo, phone, jt808DeviceId} =
          jtDevicePlaybackParamsRef.current;
        const params = {
          phone,
          jt808DeviceId,
          channelNo,
          cmd: 2, // 结束回放
          speed: 0, // 无快进或快退
          time: formatTime(dayjs()),
        };
        await jt808Command9202Request(params);
        jtDevicePlaybackParamsRef.current = null;
        flvPlayerRef.current?.pause();
        flvPlayerRef.current?.unload();
        flvPlayerRef.current?.detachMediaElement();
        flvPlayerRef.current?.destroy();
        flvPlayerRef.current = null;
      } catch (error) {
        message.error("视频回放关闭失败");
      }
    }
  };

  // 视频播放
  const onPlay = async (item: any, index: number) => {
    debugger
    if (!item.url) {
      const {jt808DeviceId} = carDetail;
      try {
        setPlayLoading({...playLoading, [index]: true});
        await closeJtDevicePlayback();
        const {channelNo, start, end, type, codeStream, storageType} = item;
        if (item.videoSource === "dvr") {
          // 记录仪视频播放
          setDvrVideoVisible(true);
        } else if (item.videoSource === "ftp") {
          // ftp服务器视频播放
          setDvrVideoVisible(false);
          const jtDeviceRes = await jtDeviceDetailRequest(jt808DeviceId);
          if (jtDeviceRes.data?.data?.filePath) {
            const params = {...item};
            params.jtDeviceRes = jtDeviceRes.data?.data?.filePath;
            const url = await deviceOperateFtpResource(params);
            setFtpVideoUrl(url);
          } else {
            const url = await operateFtpResource(item);
            setFtpVideoUrl(url);
          }
        } else if (item.videoSource === "adas") {
          // 部标机设备资源视频播放
          setDvrVideoVisible(false);
          const {terminalPhone, jt808DeviceId, version} = carDetail;
          const params = {
            phone: terminalPhone,
            jt808DeviceId,
            channelNo,
            codeStream,
            start: formatTime(start),
            end: formatTime(end),
            type,
            storageType,
            playbackMode: 0, // 正常回放
            speed: 0, // 无快进或快退
          };
          const res = await jt808Command9201Request(params);
          if (res.data?.data) {
            jtDevicePlaybackParamsRef.current = params;
          //   if (flvjs.isSupported()) {
          //     const videoElement = document.getElementById(
          //       "alarmDetailVideo"
          //     ) as HTMLMediaElement;
          //     const flvPlayer = flvjs.createPlayer({
          //       type: "flv",
          //       isLive: true,
          //       cors: true,
          //       hasAudio: true,
          //       hasVideo: true,
          //       duration: dayjs(end).diff(dayjs(start)),
          //       url: `/video/${operateJt808VersionPath(
          //         version
          //       )}/${terminalPhone}-${channelNo}`,
          //     });
          //     flvPlayerRef.current = flvPlayer;
          //     flvPlayer.attachMediaElement(videoElement);
          //     flvPlayer.load();
          //     flvPlayer.play();
          //   }
            if (mpegts.getFeatureList().mseLivePlayback) {
              const videoElement = document.getElementById(
                "alarmDetailVideo"
              ) as HTMLMediaElement;
              const flvPlayer = mpegts.createPlayer({
                type: "flv",
                isLive: true,
                cors: true,
                hasAudio: true,
                hasVideo: true,
                duration: dayjs(end).diff(dayjs(start)),
                url: `${window.location.protocol}//${res.data?.data?.domain}/video/${operateJt808VersionPath(
                  version
                )}/${terminalPhone}-${channelNo}`,
                // url: `https://${res.data?.data?.domain}/video/${operateJt808VersionPath(
                //   version
                // )}/${terminalPhone}-${channelNo}`,
                // url: `http://${res.data?.data?.ip}:${res.data?.data?.port}/video/${terminalPhone}-${channelNo}`,
              });
              flvPlayerRef.current = flvPlayer;
              flvPlayer.attachMediaElement(videoElement);
              flvPlayer.load();
              flvPlayer.play();
            }
          }
        }
        setPlayLoading({...playLoading, [index]: false});
      } catch (error) {
        setPlayLoading({...playLoading, [index]: false});
      }
    }
    if (item.url) {
      // if (item.manufacturer === '4') {
      //   fetch(item.url)
      //     .then((res) => {
      //       if (!res.body) {
      //         throw new Error("没有视频");
      //       }
      //       // return res.body;
      //       return res.arrayBuffer()
      //     })
      //     .then((stream) => {
      //       console.log(stream, 'streamstreamstream')
      //       streamTransfer(stream, 'alarmDetailVideo')
      //     });
      // }
      // if (item.manufacturer !== '4') {
      //   setFtpVideoUrl(item.url);
      // }
      setFtpVideoUrl(item.url);
    }
  };

  // 视频下载
  const onDownload = async (item: any, index: number) => {
    const {beginTime, endTime, videoSource} = item;
    if (!item.url) {
      const {jt808DeviceId} = carDetail;
      try {
        setDownloadLoading({...downloadLoading, [index]: true});
        const {beginTime, endTime, videoSource} = item;
        if (videoSource === "dvr") {
          // 记录仪视频下载
          await downloadFile(
            changeUrlProtocol(data.attachUrl),
            "记录仪视频.mp4"
          );
        } else if (item.videoSource === "ftp") {
          // ftp服务器视频下载
          const jtDeviceRes = await jtDeviceDetailRequest(jt808DeviceId);
          if (jtDeviceRes.data?.data?.filePath) {
            const params = {...item};
            params.jtDeviceRes = jtDeviceRes.data?.data?.filePath;
            const url = await deviceOperateFtpResource(params);
            downloadUrl(
              url,
              `${formatTime(beginTime)}-${formatTime(endTime)}.mp4`
            );
          } else {
            const url = await operateFtpResource(item);
            downloadUrl(
              url,
              `${formatTime(beginTime)}-${formatTime(endTime)}.mp4`
            );
          }
        }
        setDownloadLoading({...downloadLoading, [index]: false});
      } catch (error) {
        setDownloadLoading({...downloadLoading, [index]: false});
      }
    }
    if (item.url) {
      setDownloadLoading({...downloadLoading, [index]: true});
      try {
        await downloadFile(
          item.url,
          `${formatTime(beginTime)}-${formatTime(endTime)}.mp4`,
          false
        );
        setDownloadLoading({...downloadLoading, [index]: false});
      } catch (e) {
        setDownloadLoading({...downloadLoading, [index]: false});
      }
    }
  };

  // 渲染视频来源列
  const renderVideoSource = (value: string) => {
    const obj: any = {
      dvr: "行车记录仪",
      ftp: "ftp服务器",
      adas: "部标机设备",
    };
    return obj[value] || "";
  };

  // 关闭弹窗
  const onCloseModal = () => {
    // 销毁地图释放内存
    destroyAliMap(mapDomRef.current, mapRef.current);
    closeModal();
  };

  // 视频表格列显示
  const videoColumns = [
    {
      title: "视频来源",
      dataIndex: "videoSource",
      width: 110,
      ellipsis: {showTitle: false},
      render: (value: string) => (
        <RenderEllipsisColumn text={renderVideoSource(value)}/>
      ),
    },
    {
      title: "通道号",
      dataIndex: "channelNo",
      width: 80,
    },
    {
      title: "开始时间",
      dataIndex: "beginTime",
      width: 120,
      ellipsis: {showTitle: false},
      render: (value: string, item: any) => (
        <RenderEllipsisColumn
          text={formatTime(item.videoSource === "ftp" ? value : item.start)}
        />
      ),
    },
    {
      title: "结束时间",
      dataIndex: "endTime",
      width: 120,
      ellipsis: {showTitle: false},
      render: (value: string, item: any) => (
        <RenderEllipsisColumn
          text={formatTime(item.videoSource === "ftp" ? value : item.end)}
        />
      ),
    },
    {
      title: "操作",
      dataIndex: "operate",
      width: 100,
      fixed: "right" as const,
      render: (value: any, item: any, index: number) => (
        <div className={pageStyles.tableOperateColumn}>
          {item.url && (
            <>
              <Button
                type="link"
                onClick={() => onPlay(item, index)}
                loading={Boolean(playLoading[index])}
                // 记录仪没报视频上传成功，但是七牛上有数据时显示红色按钮
                danger={item.videoSource === "dvr" && !data.hasAttach}
              >
                播放
              </Button>
              {/* ftp服务器视频资源支持下载 */}
              {/* {["ftp"].includes(item.videoSource) && ( */}
              {
                <Button
                  type="link"
                  onClick={() => onDownload(item, index)}
                  loading={Boolean(downloadLoading[index])}
                >
                  下载
                </Button>
              }
            </>
          )}
          {!item.url && (
            <>
              <Button
                type="link"
                onClick={() => onPlay(item, index)}
                loading={Boolean(playLoading[index])}
                // 记录仪没报视频上传成功，但是七牛上有数据时显示红色按钮
                danger={item.videoSource === "dvr" && !data.hasAttach}
              >
                播放
              </Button>
              {/* ftp服务器视频资源支持下载 */}
              {["ftp"].includes(item.videoSource) && (
                <Button
                  type="link"
                  onClick={() => onDownload(item, index)}
                  loading={Boolean(downloadLoading[index])}
                >
                  下载
                </Button>
              )}
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Modal
      title="报警详情"
      open
      onCancel={onCloseModal}
      centered
      bodyStyle={{maxHeight: "80vh", overflow: "auto"}}
      maskClosable={false}
      width="80%"
      footer={null}
    >
      <Row className={styles.firstRow} gutter={formSearchGutter}>
        <Col span={12}>
          <div>视频播放</div>
          {dvrVideoVisible ? (
            // 记录仪视频播放支持添加字幕的组件
            <RecorderVideoPreviewModal
              showModal={false}
              url={data?.attachUrl || alarmResourceInfo[data?.alarmResourceKey]}
              fileName={`${data?.reportedTime}  ${renderEventType(data)}`}
              showTransferButton={data?.needTrans}
              resourceKey={data?.resourceKey || data?.alarmResourceKey}
              transferCallback={(url: string) => transferCallback(url)}
              showComment={
                Boolean(data.hasAttach) &&
                data.type === "alarm" &&
                getInnerPermissionEnable(
                  "/car-admin/drive-analysis/video-comment"
                )
              }
              errorMessage={driveVideoErrorMessage?.[data.eventCode]}
              needTrans={data?.needTrans}
              rawUrl={data?.rawUrl}
            />
          ) : (
            <video controls src={ftpVideoUrl} id="alarmDetailVideo"/>
          )}
        </Col>
        <Col span={12}>
          <div>报警详细信息</div>
          <AlarmInfoModal
            data={data}
            infoKey="carAlarm"
            onlyTable
            scroll={{y: 180}}
          />
        </Col>
      </Row>
      <Row className={styles.secondRow} gutter={formSearchGutter}>
        <Col span={12}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div>视频文件</div>
            <Button onClick={() => {
              setVideoData([]);
              getFtpResource()
            }}>刷新</Button>
          </div>

          <CommonTable
            dataSource={videoData}
            columns={videoColumns}
            columnFilter={false}
            scroll={{y: 180}}
            loading={loading}
            pagination={false}
          />
        </Col>
        <Col span={12}>
          <div>地图位置</div>
          <div ref={mapDomRef} className={styles.alarmDetailMap}/>
        </Col>
      </Row>
    </Modal>
  );
};

export default AlarmDetailModal;
