import React, { createElement, useEffect, useState } from "react";
import styles from "./index.module.scss";
import Icon from "@/components/icon";
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Timeline,
} from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import dayjs from "dayjs";
import {
  versionInfoUpsert,
  versionInfoList,
  delVersionInfo,
} from "@/service/home";
import moment from "moment";
import { getInnerPermissionEnable } from "@/utils/permission";
import { overflow } from "html2canvas/dist/types/css/property-descriptors/overflow";

export const VersionList = () => {
  const [visible, setVisible] = useState(false);
  const [createVisible, setCreateVisible] = useState(false);
  const [content, setContent] = useState("");
  const [versionList, setVersionList] = useState<any[]>([]);
  const dateChange = (value: any) => {};
  const dateOK = (value: any) => {};
  const [versionForm] = Form.useForm();
  const updateVersion = (value: any) => {
    const params = { ...value };
    params.onlineTime = dayjs(params.onlineTime).format("YYYY-MM-DD HH:mm:ss");
    if (!params.id) {
      delete params.id;
    }
    versionInfoUpsert(params).then((res) => {
      if (res?.data?.code == "0") {
        message.success("保存成功");
        getVersionList();
        setCreateVisible(false)
      }
    });
  };
  const getVersionList = () => {
    versionInfoList().then((res) => {
      if (res?.data?.data) {
        setVersionList(res.data.data);
      }
    });
  };

  useEffect(() => {
    if (visible) {
        getVersionList();
    }  
  }, [visible]);

  const editVersion = (item: any) => {
    const newItem = { ...item };
    newItem.onlineTime = moment(newItem.onlineTime, "YYYY-MM-DD HH:mm:ss");
    setCreateVisible(true);
    setTimeout(() => {
      versionForm.setFieldsValue({ ...newItem });
    }, 100);
  };

  const delVersion = (item: any) => {
    delVersionInfo(item.id).then((res: any) => {
      if (res?.data?.code == "0") {
        message.success("删除成功！");
        getVersionList();
      }
    });
  };

  return (
    <>
      <div
        className={styles.versionToggleBox}
        onClick={() => {
          setVisible(true);
        }}
      >
        {createElement(Icon, {
          type: "icon-version",
          style: { fontSize: "26px", color: "#1890ff" },
        })}
      </div>
      <Modal
        maskClosable={false}
        title="版本上线记录"
        open={visible}
        onCancel={() => {
          setVisible(false);
        }}
        onOk={() => {
          setVisible(false);
        }}
        width={800}
      >
        <div style={{ marginBottom: "10px", maxHeight: '600px' }}>
          {getInnerPermissionEnable("/car-admin/version/publish") && (
            <Button
              type="primary"
              onClick={() => {
                setCreateVisible(true);
              }}
            >
              新增版本
            </Button>
          )}
        </div>
        <div style={{maxHeight: '600px', overflowY: 'auto' }}>
        <Timeline>
          {versionList.map((item: any) => {
            return (
              <Timeline.Item key={item.id}>
                <Descriptions>
                  <Descriptions.Item label="版本号" span={2}>
                    {item.name}
                  </Descriptions.Item>
                  <Descriptions.Item label="上线时间" span={2}>
                    {item.onlineTime}
                  </Descriptions.Item>
                  <Descriptions.Item label="上线内容" span={3}>
                    <div dangerouslySetInnerHTML={{ __html: item.content }} />
                  </Descriptions.Item>
                </Descriptions>
                {getInnerPermissionEnable("/car-admin/version/publish") && (
                  <Row gutter={10}>
                    <Col>
                      <Button
                        onClick={() => {
                          editVersion(item);
                        }}
                      >
                        编辑
                      </Button>
                    </Col>
                    <Col>
                    <Popconfirm title="是否删除该条记录" onConfirm={() => {delVersion(item)}}>
                      <Button
                      >
                        删除
                      </Button>
                      </Popconfirm>
                    </Col>
                  </Row>
                )}
              </Timeline.Item>
            );
          })}
        </Timeline>
        </div>
       
      </Modal>
      <Modal
        title="版本详情"
        open={createVisible}
        onCancel={() => {
          setCreateVisible(false);
        }}
        onOk={versionForm.submit}
        width={800}
      >
        <Form form={versionForm} onFinish={updateVersion}>
          <Row gutter={10}>
            <Form.Item hidden name="id">
              <Input></Input>
            </Form.Item>
            <Col span={12}>
              <Form.Item label="上线版本" name={"name"} required>
                <Input></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="上线时间" name="onlineTime" required>
                <DatePicker
                  showTime
                  onChange={dateChange}
                  onOk={dateOK}
                  format="YYYY-MM-DD HH:mm:ss"
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="上线内容" name="content">
            <ReactQuill
              value={content}
              onChange={setContent}
              modules={{
                toolbar: [["bold", "italic", "underline", "blockquote"]],
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
