// extracted by mini-css-extract-plugin
export default {"primaryColor":"#1890ff","backColor":"#f0f2f5","buttonSpacing":"8px","textButtonSpacing":"4px","type":"jtDeviceAlarmCompare_type__UEoij","time":"jtDeviceAlarmCompare_time__ITSqT","operate":"jtDeviceAlarmCompare_operate__TfV63","speed":"jtDeviceAlarmCompare_speed__ETIu0","evalute":"jtDeviceAlarmCompare_evalute__OaoP3","headerBlock":"jtDeviceAlarmCompare_headerBlock__rwFqJ","leftHeader":"jtDeviceAlarmCompare_leftHeader__9yi0Y","rightHeader":"jtDeviceAlarmCompare_rightHeader__TTS3t","scrollHeader":"jtDeviceAlarmCompare_scrollHeader__Cv8z8","headerGroupTitle":"jtDeviceAlarmCompare_headerGroupTitle__ww2l1","headerTitle":"jtDeviceAlarmCompare_headerTitle__OP7zv","title":"jtDeviceAlarmCompare_title__eeDJd","contentBlock":"jtDeviceAlarmCompare_contentBlock__znLaJ","alarmIntervalBlock":"jtDeviceAlarmCompare_alarmIntervalBlock__XsNt6","leftPart":"jtDeviceAlarmCompare_leftPart__3ikYb","rightPart":"jtDeviceAlarmCompare_rightPart__+3wr8","tableRow":"jtDeviceAlarmCompare_tableRow__8+vyo","tableCell":"jtDeviceAlarmCompare_tableCell__7JCgs","formDeviceTitle":"jtDeviceAlarmCompare_formDeviceTitle__3c+NP","formDeviceBlock":"jtDeviceAlarmCompare_formDeviceBlock__9bUdo"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"primaryColor\":\"#1890ff\",\"backColor\":\"#f0f2f5\",\"buttonSpacing\":\"8px\",\"textButtonSpacing\":\"4px\",\"type\":\"jtDeviceAlarmCompare_type__UEoij\",\"time\":\"jtDeviceAlarmCompare_time__ITSqT\",\"operate\":\"jtDeviceAlarmCompare_operate__TfV63\",\"speed\":\"jtDeviceAlarmCompare_speed__ETIu0\",\"evalute\":\"jtDeviceAlarmCompare_evalute__OaoP3\",\"headerBlock\":\"jtDeviceAlarmCompare_headerBlock__rwFqJ\",\"leftHeader\":\"jtDeviceAlarmCompare_leftHeader__9yi0Y\",\"rightHeader\":\"jtDeviceAlarmCompare_rightHeader__TTS3t\",\"scrollHeader\":\"jtDeviceAlarmCompare_scrollHeader__Cv8z8\",\"headerGroupTitle\":\"jtDeviceAlarmCompare_headerGroupTitle__ww2l1\",\"headerTitle\":\"jtDeviceAlarmCompare_headerTitle__OP7zv\",\"title\":\"jtDeviceAlarmCompare_title__eeDJd\",\"contentBlock\":\"jtDeviceAlarmCompare_contentBlock__znLaJ\",\"alarmIntervalBlock\":\"jtDeviceAlarmCompare_alarmIntervalBlock__XsNt6\",\"leftPart\":\"jtDeviceAlarmCompare_leftPart__3ikYb\",\"rightPart\":\"jtDeviceAlarmCompare_rightPart__+3wr8\",\"tableRow\":\"jtDeviceAlarmCompare_tableRow__8+vyo\",\"tableCell\":\"jtDeviceAlarmCompare_tableCell__7JCgs\",\"formDeviceTitle\":\"jtDeviceAlarmCompare_formDeviceTitle__3c+NP\",\"formDeviceBlock\":\"jtDeviceAlarmCompare_formDeviceBlock__9bUdo\"}";
        // 1739351926130
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  