// extracted by mini-css-extract-plugin
export default {"operationGroupManage":"operationGroupManage_operationGroupManage__PrIJa","leftPart":"operationGroupManage_leftPart__APJAd","buttonBox":"operationGroupManage_buttonBox__F9-Jd","groupBox":"operationGroupManage_groupBox__t2VNH","groupItem":"operationGroupManage_groupItem__8DeuY","rightPart":"operationGroupManage_rightPart__+tAlW","title":"operationGroupManage_title__rWi56","treeBox":"operationGroupManage_treeBox__go0oq","searchBox":"operationGroupManage_searchBox__Z0wit","tree":"operationGroupManage_tree__cyajN","placeholderBox":"operationGroupManage_placeholderBox__OBS-N"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"operationGroupManage\":\"operationGroupManage_operationGroupManage__PrIJa\",\"leftPart\":\"operationGroupManage_leftPart__APJAd\",\"buttonBox\":\"operationGroupManage_buttonBox__F9-Jd\",\"groupBox\":\"operationGroupManage_groupBox__t2VNH\",\"groupItem\":\"operationGroupManage_groupItem__8DeuY\",\"rightPart\":\"operationGroupManage_rightPart__+tAlW\",\"title\":\"operationGroupManage_title__rWi56\",\"treeBox\":\"operationGroupManage_treeBox__go0oq\",\"searchBox\":\"operationGroupManage_searchBox__Z0wit\",\"tree\":\"operationGroupManage_tree__cyajN\",\"placeholderBox\":\"operationGroupManage_placeholderBox__OBS-N\"}";
        // 1739351923277
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  